/* ROBOTO */
@font-face {
    font-family: 'Roboto';
    src: url(../fonts/roboto/Roboto-Bold.woff2) format('woff2'),
        url(../fonts/roboto/Roboto-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Roboto';
    src: url(../fonts/roboto/Roboto-Medium.woff2) format('woff2'),
        url(../fonts/roboto/Roboto-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Roboto';
    src: url(../fonts/roboto/Roboto-Regular.woff2) format('woff2'),
        url(../fonts/roboto/Roboto-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

/* SF PRO */

@font-face {
    font-family: 'SF';
    src: url(../fonts/sf/SFProDisplay-Bold.woff2) format('woff2'),
        url(../fonts/sf/SFProDisplay-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SF';
    src: url(../fonts/sf/SFProDisplay-Semibold.woff2) format('woff2'),
        url(../fonts/sf/SFProDisplay-Semibold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF';
    src: url(../fonts/sf/SFProDisplay-Medium.woff2) format('woff2'),
        url(../fonts/sf/SFProDisplay-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}