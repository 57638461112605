.about {
    padding-top: 164px;
}

.about .about__inner {
    display: flex;
    align-items: center;
    gap: 0 41px;
}

.about__wrapper {
    width: calc(50% - 16px);
}

.about__block {
    margin-left: auto;
    width: 100%;
    max-width: 544px;
    border: 1px solid #e8e8ec;
    border-radius: 16px;
    background-color: #fdfdfe;
    padding: 50px 0 46px 30px;
    overflow: hidden;
    position: relative;
}

.about__block::after {
    content: '';
    position: absolute;
    top: 0;
    left: -96px;
    filter: blur(100px);
    background-color: rgb(255, 235, 212);
    width: 240px;
    height: 240px;
    top: 36px;
    transform: rotate(97.71deg);
    border-radius: 50%;
}

.about__block::before {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    filter: blur(100px);
    background-color: rgb(255, 235, 212);
    width: 240px;
    height: 240px;
    right: -27px;
    top: 77px;
    transform: rotate(97.71deg);
}

.about__purple {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    filter: blur(100px);
    background-color: #f2e2fc;
    width: 240px;
    height: 240px;
    left: 160px;
    top: 14px;
    transform: rotate(97.71deg);
}

.about__swiper {
    margin-top: 31px;
    z-index: 5;
}

.about__video {
    width: 100%;
}

.about__descr {
    max-width: 584px;
    width: calc(50% - 20px);
}

.about__title {
    font-size: 35px;
    font-weight: 700;
    color: var(--blue);
}

.about__text {
    margin-top: 24px;
    color: var(--gray);
    font-size: 20px;
    line-height: 28px;
    max-width: 550px;
}


.about__count {
    min-width: 21px;
    min-height: 21px;
    background-color: var(--white);
    color: var(--blue);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: .4s all linear;
    font-weight: 500;
}

.about__top {
    display: flex;
    gap: 0 23px;
    position: relative;
    padding-right: 10px;
    z-index: 5;

    align-items: flex-start;
}

.about__top-title {
    font-size: 24px;
    color: var(--black);
    font-weight: 700;
}

.about__top-count {
    font-size: 14px;
    font-family: 'SF', sans-serif;
    margin-top: 4px;
    color: rgb(96, 100, 108);
    margin-bottom: 2px;
}

.about__slide {
    cursor: pointer;
}

.about__slide-wrapper {
    position: absolute;
    top: 8.3px;
    left: 7.5px;
    color: var(--white);
    text-align: start;
    color: #fcfcfd;
    font-size: 14px;
}


.about__date {
    font-size: 12px;
    opacity: .7;
}

.about__basket {
    width: 37.25px;
    height: 37.25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8.3px;
    right: 7.5px;
    border-radius: 4.57px;
    background-color: #d2deff;
    stroke: #1F2D5C;
}

.about__link {
    position: absolute;
    bottom: 11.64px;
    left: 7.5px;
    box-sizing: border-box;
    border: 1.16px solid #1f2d5c;
    border-radius: 7.05px;
    background-color: #1f2d5cb3;
    font-size: 12px;
    font-weight: 500;
    color: var(--white);
    padding: 6.98px 11.64px;
    display: flex;
    gap: 4.6px;
    align-items: center;
}

.about .about__top-button {
    font-size: 16px;
    height: 48px;
}

.about__top-button:hover .about__count {
    background-color:#1F2D5C;
    color: var(--white);
}

@media (max-width:1200px) {
    .about {
        padding-top: 280px;
    }

    .about__top-title {
        font-size: 20px;
    }

    .about .about__top-button {
        font-size: 14px;
        padding: 7.5px 10px;
    }

    .about__count {
        font-size: 10px;
    }

    .about .about__inner {
        gap: 0 32px;
    }


}

@media (max-width:960px) {
    .about__top-title {
        font-size: 16px;
    }

    .about__text {
        font-size: 16px;
    }
}

@media (max-width:760px) {
    .about {
        padding-top: 380px;
    }

    .about .about__inner {
        flex-direction: column;
        gap: 24px 0;
    }

    .about__block {
        max-width: 100%;
        margin: 0;
    }

    .about__wrapper {
        width: 100%;
        order: 1;
    }

    .about__descr {
        max-width: 100%;
        width: 100%;
    }

    .about__title {
        text-align: center;
    }

    .about__text span {
        display: block;
        margin-top: 30px;
    }
}

@media (max-width:480px) {
    .about {
        padding-top: 700px;
    }

    .about__block {
        padding: 32px 0 29.8px 19.47px;
    }

    .about__top {
        gap: 0 12px;
    }

    .about__top-count {
        font-size: 10px;
    }

    .about__basket {
        width: 24px;
        height: 24px;
    }

    .about__basket svg {
        width: 10px;
        height: 9px;
    }
}