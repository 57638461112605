@import url(./reset.css);
@import url(./fonts.css);


:root {
    --white: #fff;
    --page: #F9F9F9;
    --black: #1c2024;
    --blue: #1f2d5c;
    --gray: #60646c;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--page);
}

.container-b {
    max-width: 1400px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.container {
    max-width: 1244px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}


.main {
    position: relative;
    overflow: hidden;
}

.button {
    border-radius: 8px;
    display: flex;
    gap: 0 4px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    border-radius: 8px;
    color: var(--white);
    padding: 12px 24px;
    background-color: #1f2d5c;
    transition: .5s all ease-in;
    fill: var(--white);
}


.button:hover {
    background-color: transparent;
    outline: 1px solid rgb(139, 141, 152);
    color: rgb(31, 45, 92);
    fill:  rgb(31, 45, 92);
}

.button:disabled {
    background: rgb(193, 208, 255);
}